/* * UWA Universal Navigation
 * * For SiteCore SXA Student and News sites
 * * Timothy Phillips, Digital Channel, September 2024
 * */
@import url("https://static.weboffice.uwa.edu.au/visualid/fonts/petrona/petrona.css");
@import url("https://static.weboffice.uwa.edu.au/visualid/fonts/plus-jakarta-sans/plus-jakarta-sans.css");
@media screen and (min-width: 0px) {
  :root {
    --bmr-page-margin: 16px;
    --bmr-grid-padding: 8px;
    --bmr-grid-offset: -8px;
  }
}
@media screen and (min-width: 576px) {
  :root {
    --bmr-page-margin: 16px;
    --bmr-grid-padding: 8px;
    --bmr-grid-offset: -8px;
  }
}
@media screen and (min-width: 768px) {
  :root {
    --bmr-page-margin: 24px;
    --bmr-grid-padding: 8px;
    --bmr-grid-offset: -8px;
  }
}
@media screen and (min-width: 992px) {
  :root {
    --bmr-page-margin: 24px;
    --bmr-grid-padding: 8px;
    --bmr-grid-offset: -8px;
  }
}
@media screen and (min-width: 1200px) {
  :root {
    --bmr-page-margin: 24px;
    --bmr-grid-padding: 12px;
    --bmr-grid-offset: -12px;
  }
}
@media screen and (min-width: 1440px) {
  :root {
    --bmr-page-margin: 96px;
    --bmr-grid-padding: 12px;
    --bmr-grid-offset: -12px;
  }
}

.uwa__match-header-width {
  max-width: 1200px;
  box-sizing: content-box;
  padding-left: 48px;
  padding-right: 48px;
  padding-left: var(--bmr-page-margin);
  padding-right: var(--bmr-page-margin);
  margin-left: auto;
  margin-right: auto;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
}

/* UWA Header layout and global styles for SXA and General tempaltes */
[class*=uwa-nav] button, [class*=uwa-nav] a, [class*=uwa-nav] img, [class*=uwa-nav] ul, [class*=uwa-nav] ol, [class*=uwa-nav] li, [class*=uwa-nav] h1, [class*=uwa-nav] h2, [class*=uwa-nav] h3, [class*=uwa-nav] p, [class*=uwa-nav] button, [class*=uwa-nav] input, [id^=uwa-header] button, [id^=uwa-header] a, [id^=uwa-header] img, [id^=uwa-header] ul, [id^=uwa-header] ol, [id^=uwa-header] li, [id^=uwa-header] h1, [id^=uwa-header] h2, [id^=uwa-header] h3, [id^=uwa-header] p, [id^=uwa-header] button, [id^=uwa-header] input {
  font-family: "Plus Jakarta Sans", "Century Gothic", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align: baseline;
  text-transform: none;
  transition: none;
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;
  box-shadow: none;
  line-height: inherit;
}
[class*=uwa-nav] ul, [class*=uwa-nav] li, [id^=uwa-header] ul, [id^=uwa-header] li {
  list-style: none;
}
[class*=uwa-nav] menu, [id^=uwa-header] menu {
  margin-top: 0;
  margin-bottom: 0;
}
[class*=uwa-nav] img, [id^=uwa-header] img {
  vertical-align: bottom;
}

header {
  z-index: 100;
  position: relative;
}

#header .row {
  margin-left: auto;
  margin-right: auto;
}

[id^=uwa-header__row] {
  width: 100% !important;
  max-width: 100% !important;
  padding: 0px;
  margin: 0px;
}
[id^=uwa-header__row] > .component-content {
  width: 100%;
}
[id^=uwa-header__row] > .component-content > * {
  max-width: 1200px;
  box-sizing: content-box;
  padding-left: 48px;
  padding-right: 48px;
  padding-left: var(--bmr-page-margin);
  padding-right: var(--bmr-page-margin);
  margin-left: auto;
  margin-right: auto;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 768px) {
  [id^=uwa-header__row] > .component-content > * {
    align-items: flex-start;
    column-gap: 24px;
  }
}
[id^=uwa-header__row] .grid-padding-x > .cell {
  padding: 0;
  flex-shrink: 0;
  width: auto;
}
[id^=uwa-header__row] .grid-padding-x > .cell > .component-content > * {
  margin: 0;
}
[id^=uwa-header__row] .grid-padding-x .grid-padding-x {
  margin: 0;
}

#uwa-header__row-primary, #uwa-header__row-single, #uwa-header__row-secondary {
  background-color: #003087;
  color: #ffffff;
}
#uwa-header__row-primary > .component-content > *, #uwa-header__row-single > .component-content > *, #uwa-header__row-secondary > .component-content > * {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  column-gap: 24px;
}
#uwa-header__row-primary > .component-content > * > *:first-child, #uwa-header__row-single > .component-content > * > *:first-child, #uwa-header__row-secondary > .component-content > * > *:first-child {
  padding-right: 24px;
}

#uwa-header__row-primary > .component-content > *, #uwa-header__row-single > .component-content > * {
  flex-flow: row nowrap;
}
#uwa-header__row-primary > .component-content > * > *, #uwa-header__row-single > .component-content > * > * {
  flex-shrink: 0;
  flex-grow: 0;
  width: auto;
}
#uwa-header__row-primary #uwa-header__container-logo, #uwa-header__row-single #uwa-header__container-logo {
  flex-grow: 1;
  flex-shrink: 0;
}
@media (max-width: 471.99px) {
  #uwa-header__row-primary #uwa-header__container-logo a + a, #uwa-header__row-single #uwa-header__container-logo a + a {
    display: none;
  }
}
#uwa-header__row-primary #uwa-header__container-menu, #uwa-header__row-single #uwa-header__container-menu {
  display: none;
}
@media (max-width: 767.99px) {
  #uwa-header__row-primary > .component-content > *, #uwa-header__row-single > .component-content > * {
    column-gap: 0;
  }
  #uwa-header__row-primary > .component-content > * > *, #uwa-header__row-single > .component-content > * > * {
    display: none;
  }
  #uwa-header__row-primary #uwa-header__container-logo,
  #uwa-header__row-primary #uwa-header__container-menu,
  #uwa-header__row-primary #uwa-header__container-search, #uwa-header__row-single #uwa-header__container-logo,
  #uwa-header__row-single #uwa-header__container-menu,
  #uwa-header__row-single #uwa-header__container-search {
    display: block;
  }
  #uwa-header__row-primary #uwa-header__container-logo, #uwa-header__row-single #uwa-header__container-logo {
    flex-grow: 1;
  }
}

#uwa-header__container-logo {
  padding-right: 0 !important;
}
#uwa-header__container-logo .uwa-nav__logo a:first-child {
  margin-left: -5.4px;
}

#uwa-header__container-global button {
  font-weight: 500;
}

#uwa-header__row-secondary > .component-content > * {
  flex-flow: row nowrap;
}
#uwa-header__row-secondary > .component-content > * > * {
  margin-top: -14px;
}
#uwa-header__row-secondary #uwa-header__container-title {
  max-width: 100%;
}
#uwa-header__row-secondary #uwa-header__container-title h1 {
  display: block;
  margin: 0;
  color: white;
  font-family: "Plus Jakarta Sans", "Century Gothic", sans-serif;
  flex-grow: 1;
  margin: 0;
  font-size: 21px;
  font-weight: 500;
  line-height: 30px;
  padding: 15px 0;
}
#uwa-header__row-secondary #uwa-header__container-title h1 a {
  text-decoration: none;
  color: #ffffff;
}
#uwa-header__row-secondary #uwa-header__container-title h1 a:link {
  text-decoration: none;
}
#uwa-header__row-secondary #uwa-header__container-title h1 a:hover, #uwa-header__row-secondary #uwa-header__container-title h1 a:focus-visible {
  text-decoration: underline;
}
@media (min-width: 768px) {
  #uwa-header__row-secondary #uwa-header__container-title h1 {
    padding: 13px 0 17px 0;
    white-space: nowrap;
  }
}
@media (max-width: 767.99px) {
  #uwa-header__row-secondary #uwa-header__container-title {
    flex-grow: 1;
    padding-right: 0;
  }
}

@media (min-width: 472px) {
  .uwa-header__logo-as-title #uwa-header__row-secondary #uwa-header__container-title,
  #uwa-header__container-title._lockup_as_title {
    display: none;
  }
}
#uwa-header__container-site {
  flex-grow: 1;
  flex-shrink: 1;
  overflow-x: hidden;
}
@media (max-width: 767.99px) {
  #uwa-header__container-site {
    display: none;
  }
}

#uwa-header__row-tertiary h1, #uwa-header__row-tertiary h2 {
  margin-top: 24px;
  margin-bottom: 48px;
  font-family: "Plus Jakarta Sans", "Century Gothic", sans-serif;
  font-size: 43px;
  line-height: 1.2;
  font-weight: 700;
  color: #003087;
}
@media screen and (max-width: 1200px) {
  #uwa-header__row-tertiary h1, #uwa-header__row-tertiary h2 {
    font-size: clamp(24px, 24px + 19 * (100vw - 320px) / 880, 43px);
  }
}

.uwa-nav__logo {
  white-space: nowrap;
  display: flex;
  flex-flow: row nowrap;
  padding-top: 16px;
  padding-bottom: 16px;
}
.uwa-nav__logo a, .uwa-nav__logo img, .uwa-nav__logo svg, .uwa-nav__logo text {
  font-family: UWA;
}
.uwa-nav__logo a {
  display: block;
}
.uwa-nav__logo img, .uwa-nav__logo svg {
  max-height: 48px;
  height: 48px;
  vertical-align: middle;
}
.uwa-nav__logo a + a {
  position: relative;
  padding-left: 16px;
  margin-left: 16px;
}
.uwa-nav__logo a + a:before {
  display: block;
  position: absolute;
  content: "";
  left: -1px;
  top: 0px;
  width: 2px;
  bottom: 2px;
  background-color: #ffffff;
}
.uwa-nav__logo a + a img, .uwa-nav__logo a + a svg {
  height: 46px;
  max-height: 46px;
}
.uwa-nav__logo svg text {
  font-family: UWA, "UWA Regular", "UWA-regular";
  fill: #ffffff;
}

.uwa-nav__links button, .uwa-nav__links a[href] {
  position: relative;
  outline: none;
  background: none;
  display: block;
  padding: 0;
  font-weight: 400 !important;
  font-size: 15px !important;
  padding-bottom: 2px !important;
  border-width: 0px !important;
  border-bottom: 1px solid transparent !important;
  white-space: nowrap;
  text-decoration: none;
  color: #ffffff;
}
.uwa-nav__links button._menu:before, .uwa-nav__links a[href]:before {
  position: absolute;
  background-color: #00A3E1;
  content: "";
  left: 50%;
  right: 50%;
  width: 0%;
  bottom: 0px;
  height: 2px;
  transition-property: left, right, width;
  transition-duration: 200ms;
  transition-timing-function: ease;
}
.uwa-nav__links button._menu:hover:before, .uwa-nav__links button._menu:focus-visible:before, .uwa-nav__links button._menu.__open:before, .uwa-nav__links a[href]:hover:before, .uwa-nav__links a[href]:focus-visible:before, .uwa-nav__links a[href].__open:before {
  left: 0;
  right: 0;
  width: 100%;
}
.uwa-nav__links button._menu {
  text-decoration: none !important;
  cursor: progress !important;
  position: relative;
  padding-right: 24px;
}
.uwa-nav__links button._menu:after {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 16px;
  height: 16px;
  background-image: url("../img/menu-chevron_bright.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 30px;
  transform: rotate(0deg);
  transition: transform 200ms ease;
}
.uwa-nav__links button._menu.__open:after {
  transform: rotate(180deg);
}
.uwa-nav__links button._menu:before {
  height: 0px;
}
.uwa-nav__links button._menu.__init {
  cursor: auto !important;
}
.uwa-nav__links button._menu.__init:before {
  height: 2px;
}
.uwa-nav__links button._menu:not([data-target]) + ul, .uwa-nav__links button._menu:not([data-target]) + div {
  display: none;
}
.uwa-nav__links button._icon {
  background-repeat: no-repeat;
  border: 0px solid transparent !important;
  text-decoration: none;
  background-position: center;
  background-size: 32px;
  width: 48px;
  height: 48px;
  font-size: 0px !important;
  position: relative;
}
.uwa-nav__links button._icon:before, .uwa-nav__links button._icon:after {
  display: block;
  position: absolute;
  content: "";
}
.uwa-nav__links button._icon.__open {
  background-image: url("../img/close_white.svg") !important;
}
.uwa-nav__links button._icon.__back {
  background-image: url("../img/chevron-left_white.svg") !important;
}
.uwa-nav__links button._icon.__open:before, .uwa-nav__links button._icon.__back:before {
  top: 100%;
  height: 16px;
  left: 0px;
  right: 0px;
}
.uwa-nav__links button._icon:hover:after, .uwa-nav__links button._icon:focus-visible:after {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #ffffff;
}
@media (hover: none) {
  .uwa-nav__links button._icon:after {
    display: none;
  }
}
.uwa-nav__links button#uwa-nav__mobile {
  background-image: url("../img/menu_white.svg");
  background-color: #002855;
}
.uwa-nav__links button#uwa-nav__mobile:before {
  background-color: #002855;
}
@media (min-width: 768px) {
  .uwa-nav__links button#uwa-nav__mobile {
    display: none;
  }
}
.uwa-nav__links button#uwa-nav__search {
  background-image: url("../img/search_white.svg");
  background-color: #00A3E1;
}
.uwa-nav__links button#uwa-nav__search:before {
  background-color: #00A3E1;
}

#uwa-nav__site {
  position: relative;
  height: 60px;
  align-content: center;
  overflow-x: hidden;
  width: 100%;
}
@media (max-width: 767.99px) {
  #uwa-nav__site {
    display: none !important;
  }
}
#uwa-nav__site ul {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  align-items: center;
  white-space: nowrap;
  column-gap: 24px;
  row-gap: 0;
  overflow: hidden;
  height: 30px;
  box-sizing: content-box;
  transition: height 200ms ease;
}
#uwa-nav__site ul li {
  line-height: 30px;
  height: 30px;
  z-index: 0;
}
#uwa-nav__site ul li a, #uwa-nav__site ul li button {
  height: inherit;
  display: block;
}
#uwa-nav__site ul + button.__toggle {
  position: absolute;
  z-index: 2;
  margin: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
#uwa-nav__site ul + button.__toggle button {
  max-height: 100%;
}
#uwa-nav__site ul.__sizer {
  opacity: 0;
  height: 0;
  padding-bottom: 0;
  overflow: hidden;
  margin: 0;
  flex-wrap: wrap;
  justify-content: flex-start;
}
#uwa-nav__site ul + button.__toggle {
  display: none;
  padding: 0 !important;
  font-size: 0 !important;
  width: 30px;
  height: 100%;
  box-sizing: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-color: transparent !important;
  background-size: 24px 24px;
  border: 1px solid transparent !important;
  background-image: url("../img/overflow-open_white.svg");
}
#uwa-nav__site ul + button.__toggle:hover, #uwa-nav__site ul + button.__toggle:focus-visible {
  border-color: #b5b5b5 !important;
}
#uwa-nav__site ul.__overflow {
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
}
#uwa-nav__site ul.__overflow.__open {
  height: auto;
  flex-wrap: wrap;
}
#uwa-nav__site ul.__overflow:not(.__open) + button.__toggle {
  display: block;
  background-color: #002855;
}
#uwa-nav__site ul.__overflow:not(.__open):after {
  display: block;
  z-index: 1;
  pointer-events: none;
  content: "";
  right: 0;
  top: 0;
  bottom: 0;
  width: 60px;
  position: absolute;
  background: linear-gradient(to right, transparent, #003087 70%, #003087);
}

.uwa-nav__menus-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 98;
  display: none;
}
.uwa-nav__menus-background.__open {
  background-color: rgba(0, 0, 0, 0.1);
  display: block;
}
.uwa-nav__menus-background.__shade {
  background-color: rgba(0, 0, 0, 0.2);
}

.uwa-nav__menus {
  display: none;
  z-index: 101;
  position: relative;
}
.uwa-nav__menus.__open {
  display: block;
  position: absolute;
  overflow: hidden;
  background: white;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
}
.uwa-nav__menus.__megamenu {
  left: 0;
  right: 0;
  max-width: 1200px;
  box-sizing: content-box;
  padding-left: 48px;
  padding-right: 48px;
  padding-left: var(--bmr-page-margin);
  padding-right: var(--bmr-page-margin);
  margin-left: auto;
  margin-right: auto;
}
.uwa-nav__menus.__megamenu > div, .uwa-nav__menus.__megamenu > nav {
  max-width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
@media (max-width: 767.99px) {
  .uwa-nav__menus.__megamenu > div, .uwa-nav__menus.__megamenu > nav {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}
.uwa-nav__menus.__fit {
  left: 0;
  right: 0;
}
.uwa-nav__menus.__fit > div, .uwa-nav__menus.__fit > nav {
  z-index: 1;
}
.uwa-nav__menus button.__control {
  z-index: 2;
  background-repeat: no-repeat;
  border: 0;
  outline: 0;
  background-color: transparent;
  line-height: 100px;
  font-size: 0px !important;
  background-position: center;
  background-size: 24px;
  width: 40px;
  height: 40px;
  position: absolute;
  border: 1px solid transparent !important;
  top: 0;
}
.uwa-nav__menus button.__control:hover, .uwa-nav__menus button.__control:focus {
  border-color: #b5b5b5 !important;
}
.uwa-nav__menus button.__control:after {
  display: none;
}
.uwa-nav__menus button.__control._close {
  background-image: url("../img/close_grey.svg");
  display: none;
  right: 0;
}
@media (min-width: 768px) {
  .uwa-nav__menus.__megamenu button._close {
    display: block;
  }
}

.uwa-nav__menu {
  display: none;
  z-index: 101;
  background-color: #ffffff;
  color: #0A0A0A;
  padding: 24px;
  max-width: 580px;
  min-width: 200px;
}
@media (max-width: 767.99px) {
  .uwa-nav__menu {
    padding: 24px !important;
  }
}
.uwa-nav__menu > * > * {
  max-width: 100%;
}
.uwa-nav__menu.__open {
  display: block;
}

.uwa-nav__menu a, .uwa-nav__menu a:link, .uwa-nav__menu button {
  display: inline-block;
  outline: none;
  background: none;
  text-decoration: none;
}
.uwa-nav__menu button {
  display: block;
}
.uwa-nav__menu h2 a {
  color: #0A0A0A;
}
.uwa-nav__menu ._links a[href], .uwa-nav__menu ._links button._menu, .uwa-nav__menu ._title a[href], .uwa-nav__menu ._title button._menu, .uwa-nav__menu > ul a[href], .uwa-nav__menu > ul button._menu {
  position: relative;
  color: #000000;
}
.uwa-nav__menu ._links a[href]:before, .uwa-nav__menu ._links button._menu:before, .uwa-nav__menu ._title a[href]:before, .uwa-nav__menu ._title button._menu:before, .uwa-nav__menu > ul a[href]:before, .uwa-nav__menu > ul button._menu:before {
  position: absolute;
  background-color: #00A3E1;
  content: "";
  left: 50%;
  right: 50%;
  bottom: 4px;
  height: 1px;
  transition-property: left, right;
  transition-duration: 150ms;
  transition-timing-function: ease-out;
}
.uwa-nav__menu ._links a[href]:hover:before, .uwa-nav__menu ._links a[href]:focus-visible:before, .uwa-nav__menu ._links a[href].__open:before, .uwa-nav__menu ._links button._menu:hover:before, .uwa-nav__menu ._links button._menu:focus-visible:before, .uwa-nav__menu ._links button._menu.__open:before, .uwa-nav__menu ._title a[href]:hover:before, .uwa-nav__menu ._title a[href]:focus-visible:before, .uwa-nav__menu ._title a[href].__open:before, .uwa-nav__menu ._title button._menu:hover:before, .uwa-nav__menu ._title button._menu:focus-visible:before, .uwa-nav__menu ._title button._menu.__open:before, .uwa-nav__menu > ul a[href]:hover:before, .uwa-nav__menu > ul a[href]:focus-visible:before, .uwa-nav__menu > ul a[href].__open:before, .uwa-nav__menu > ul button._menu:hover:before, .uwa-nav__menu > ul button._menu:focus-visible:before, .uwa-nav__menu > ul button._menu.__open:before {
  left: 0;
  right: 0;
}
.uwa-nav__menu ._links ul button._menu {
  font-size: 15px !important;
  border: none !important;
  position: relative;
  padding-right: 24px;
}
.uwa-nav__menu ._links ul button._menu:after {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 20px;
  height: 20px;
  top: 50%;
  margin-top: -10px;
  background-image: url("../img/chevron-right_bright.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.uwa-nav__menu ._links ul button._menu:before {
  bottom: -2px;
  height: 2px !important;
}
.uwa-nav__menu ._promo {
  display: none;
  margin-top: 32px;
}
.uwa-nav__menu ._promo:has(a) {
  display: block;
}
.uwa-nav__menu ._promo ul {
  width: 100%;
}
.uwa-nav__menu ._promo li {
  margin-bottom: 16px;
}
.uwa-nav__menu ._promo a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  min-width: 150px;
  white-space: nowrap;
  height: 50px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 2px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  background: transparent;
  font-size: 17px;
  font-weight: 500;
  font-family: "Plus Jakarta Sans", "Century Gothic", sans-serif;
  text-align: center;
  text-decoration: none;
  border-color: #000000 !important;
  background-color: #ffffff;
  color: #003087;
  max-width: 100% !important;
  min-width: 150px;
  width: auto;
  margin: 0;
}
@media screen and (max-width: 1200px) {
  .uwa-nav__menu ._promo a {
    height: clamp(47px, 47px + 3 * (100vw - 320px) / 880, 50px);
  }
}
.uwa-nav__menu ._promo a:focus, .uwa-nav__menu ._promo a:hover {
  text-decoration: none;
  background-color: #000000;
  border-color: #000000 !important;
  color: #ffffff;
}
.uwa-nav__menu ._promo a:active {
  background-color: #333333;
  border-color: #000000 !important;
  color: #ffffff;
}
.uwa-nav__menu ._promo li:first-child a {
  text-decoration: none;
  border-color: #003087 !important;
  background-color: #003087;
  color: #ffffff;
}
.uwa-nav__menu ._promo li:first-child a:focus, .uwa-nav__menu ._promo li:first-child a:hover {
  text-decoration: none;
  background-color: #000000;
  border-color: #000000 !important;
  color: #ffffff;
}
.uwa-nav__menu ._promo li:first-child a:active {
  background-color: #333333;
  border-color: #000000 !important;
  color: #ffffff;
}
.uwa-nav__menu ._title {
  position: relative;
  margin-bottom: 0;
  font-size: 15px;
}
.uwa-nav__menu ._title h2 {
  margin: 0;
  line-height: 30px;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
}
.uwa-nav__menu ._title h2 a, .uwa-nav__menu ._title h2 span {
  font-weight: inherit;
  color: inherit;
  font-size: inherit !important;
  padding-bottom: 8px;
}
.uwa-nav__menu ._title h2 a:not([href]), .uwa-nav__menu ._title h2 span:not([href]) {
  cursor: default;
}
.uwa-nav__menu ._title h2 a[href] {
  padding-right: 32px;
}
.uwa-nav__menu ._title h2 a[href]:after {
  display: inline-block;
  content: "";
  position: relative;
  width: 32px;
  height: 24px;
  margin-right: -32px;
  top: -2px;
  vertical-align: middle;
  background-image: url("../img/arrow-right_bright.svg");
  background-position: center right;
  background-repeat: no-repeat;
  background-size: 24px;
  left: 0;
  transition: left 250ms ease;
}
.uwa-nav__menu ._title h2 a[href]:hover:after, .uwa-nav__menu ._title h2 a[href]:focus:after {
  left: 4px;
}

.uwa-nav__menu:not(._megamenu):has(ul > :nth-child(8)) ._links {
  width: 524px;
  max-width: 100%;
}
.uwa-nav__menu ul {
  column-width: 250px;
  gap: 24px;
  margin: 0;
}
.uwa-nav__menu ul a, .uwa-nav__menu ul strong, .uwa-nav__menu ul button:not(.__control) {
  display: inline-block;
  line-height: 24px;
  font-size: 15px;
  text-align: left;
  padding-bottom: 8px;
  padding-top: 8px;
}
@media (hover: hover) {
  .uwa-nav__menu ul a, .uwa-nav__menu ul strong, .uwa-nav__menu ul button:not(.__control) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
.uwa-nav__menu ul button.__accordion {
  display: none;
}
.uwa-nav__menu ul li {
  break-inside: avoid-column;
  position: relative;
}
.uwa-nav__menu ul li:last-child {
  margin: 0;
}
.uwa-nav__menu ul ul {
  display: none;
}
.uwa-nav__menu.__mobile-root {
  min-width: 300px;
  max-width: 100%;
}
.uwa-nav__menu.__mobile-root ul {
  column-width: unset;
}
.uwa-nav__menu.__mobile-root button, .uwa-nav__menu.__mobile-root a {
  font-weight: 600 !important;
}
.uwa-nav__menu.__mobile-root a[href]:before {
  bottom: 0;
  height: 2px;
}
.uwa-nav__menu.__mobile-root button {
  width: 100%;
}
.uwa-nav__menu.__mobile-root li:not(:last-child) {
  margin-bottom: 8px;
}
.uwa-nav__menu.__mobile-root ul + ul {
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid #003087;
}
.uwa-nav__menu.__mobile-root ul + ul._promo {
  margin-top: 0;
  border-top: none;
}

.uwa-nav__menu._megamenu {
  padding-top: 32px;
  padding-bottom: 32px;
}
.uwa-nav__menu._megamenu > * > * {
  box-sizing: border-box;
  width: 100%;
}
.uwa-nav__menu._megamenu > * > * > *:first-child {
  margin-top: 0px;
}
.uwa-nav__menu._megamenu > * > * > *:last-child {
  margin-bottom: 0px;
}
.uwa-nav__menu._megamenu ._links ul a, .uwa-nav__menu._megamenu ._links ul strong, .uwa-nav__menu._megamenu ._links ul button:not(.__control) {
  font-weight: 600;
}
.uwa-nav__menu._megamenu ._links ul li {
  padding: 0;
  column-width: 100%;
  margin-bottom: 12px;
}
.uwa-nav__menu._megamenu ._links ul li ul {
  display: block;
}
.uwa-nav__menu._megamenu ._links ul li ul a {
  font-weight: normal;
}
.uwa-nav__menu._megamenu ._links ul li ul li {
  margin-bottom: 0;
}
.uwa-nav__menu._megamenu ._title {
  margin-bottom: 16px;
}
.uwa-nav__menu._megamenu ._title p {
  margin-top: 16px;
}
.uwa-nav__menu._megamenu ._title h2 {
  color: #000000;
  font-size: 25px;
  margin-bottom: 16px;
}
.uwa-nav__menu._megamenu ._links {
  break-inside: avoid-column;
  flex-grow: 1;
}
.uwa-nav__menu._megamenu._search > * {
  display: block !important;
}
.uwa-nav__menu._megamenu._search > * > * {
  padding-right: 0 !important;
}
.uwa-nav__menu._megamenu #uwa-nav__search-suggestions {
  margin-top: 24px;
}
.uwa-nav__menu._megamenu #uwa-nav__search-suggestions li {
  display: none;
}
.uwa-nav__menu._megamenu #uwa-nav__search-suggestions li:has(a) {
  display: block;
}
@media (max-width: 767.99px) {
  .uwa-nav__menu._megamenu #uwa-nav__search-suggestions {
    display: flex;
    flex-flow: column nowrap;
    gap: 0;
  }
  .uwa-nav__menu._megamenu #uwa-nav__search-suggestions li {
    order: 1;
  }
  .uwa-nav__menu._megamenu #uwa-nav__search-suggestions ._results {
    order: 0;
  }
}
@media (min-width: 992px) {
  .uwa-nav__menu._megamenu > * {
    display: grid;
    grid-template-columns: 280px auto;
  }
  .uwa-nav__menu._megamenu ._title, .uwa-nav__menu._megamenu ._promo {
    grid-column: 1;
    padding-right: 8px;
  }
  .uwa-nav__menu._megamenu ._title li:not(:last-child), .uwa-nav__menu._megamenu ._promo li:not(:last-child) {
    margin-bottom: 16px;
  }
  .uwa-nav__menu._megamenu ._promo {
    grid-row: 2;
  }
  .uwa-nav__menu._megamenu ._promo ul {
    column-width: auto;
    display: inline-block;
    width: auto;
  }
  .uwa-nav__menu._megamenu ._promo ul a {
    width: 100%;
  }
  .uwa-nav__menu._megamenu ._links {
    grid-column: 2;
    grid-row: 1/4;
  }
}
@media (max-width: 767.99px) {
  .uwa-nav__menu._megamenu ._title p {
    display: none;
  }
  .uwa-nav__menu._megamenu ._title li:not(:last-child), .uwa-nav__menu._megamenu ._promo li:not(:last-child) {
    margin-bottom: 16px;
  }
  .uwa-nav__menu._megamenu ul {
    column-width: auto;
  }
  .uwa-nav__menu._megamenu ul > li {
    margin-bottom: 8px;
  }
  .uwa-nav__menu._megamenu ul a, .uwa-nav__menu._megamenu ul strong {
    padding-top: 8px;
  }
  .uwa-nav__menu._megamenu ul button.__accordion {
    display: block;
    border: 1px solid transparent !important;
    background-image: url("../img/chevron-down_bright.svg");
    right: 0;
  }
  .uwa-nav__menu._megamenu ul button.__accordion:before {
    display: block;
    position: absolute;
    content: "";
    width: 1px;
    background-color: #b5b5b5;
    right: 48px;
    top: -1px;
    height: 40px;
  }
  .uwa-nav__menu._megamenu ul button.__accordion:hover, .uwa-nav__menu._megamenu ul button.__accordion:focus-visible {
    border-color: #00A3E1 !important;
  }
  .uwa-nav__menu._megamenu ul button.__accordion + ul {
    position: relative;
    height: 0;
    overflow: hidden;
    box-sizing: border-box;
    transition: height 250ms ease;
  }
  .uwa-nav__menu._megamenu ul button.__accordion.__open {
    background-image: url("../img/chevron-up_bright.svg");
  }
  .uwa-nav__menu._megamenu ul button.__accordion.__open + ul {
    margin-bottom: 16px;
    border-bottom: 1px solid #b5b5b5;
  }
  .uwa-nav__menu._megamenu ul button.__accordion.__open + ul li:last-child a {
    margin-bottom: 16px;
  }
}

.uwa-nav__menu._search ._close {
  display: none !important;
}
.uwa-nav__menu._search ._form {
  flex-basis: 100%;
}
.uwa-nav__menu._search ._form form {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  background-color: none;
  padding: 0px;
}
.uwa-nav__menu._search ._form form > * {
  flex-grow: 1;
  flex-shrink: 1;
}
.uwa-nav__menu._search ._form form:before {
  content: "";
  width: 50px;
  height: 50px;
  background: url("../img/search_grey.svg") center no-repeat;
  background-size: 24px;
  z-index: 2;
  margin-right: -66px;
}
.uwa-nav__menu._search ._form form input, .uwa-nav__menu._search ._form form button {
  line-height: 40px;
  min-width: 50px;
  padding-left: 16px;
  padding-right: 16px;
  border: 1px solid transparent;
  line-height: 48px;
  font-size: 17px;
  height: 50px;
  box-sizing: border-box;
  font-weight: normal;
}
.uwa-nav__menu._search ._form form button[type=submit] {
  text-align: center;
  text-decoration: none;
  border-color: #003087 !important;
  background-color: #003087;
  color: #ffffff;
}
.uwa-nav__menu._search ._form form button[type=submit]:focus, .uwa-nav__menu._search ._form form button[type=submit]:hover {
  text-decoration: none;
  background-color: #000000;
  border-color: #000000 !important;
  color: #ffffff;
}
.uwa-nav__menu._search ._form form button[type=submit]:active {
  background-color: #333333;
  border-color: #000000 !important;
  color: #ffffff;
}
.uwa-nav__menu._search ._form form input[type=text] {
  z-index: 1;
  flex-basis: 70%;
  padding-right: 50px;
  padding-left: 50px;
  margin-right: -66px;
  border-color: #00A3E1;
}
.uwa-nav__menu._search ._form form input[type=text]:focus, .uwa-nav__menu._search ._form form input[type=text]:hover {
  border-color: #002855;
  outline: none;
  box-shadow: none;
}
.uwa-nav__menu._search ._form form input[type=text]::placeholder {
  color: #686868;
}
.uwa-nav__menu._search ._form form button[type=reset] {
  z-index: 2;
  flex-grow: 0;
  padding-left: 48px;
  padding-right: 0;
  line-height: 150px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  min-width: 50px;
  background: url("../img/close.svg") center no-repeat;
  background-size: 24px;
  border: 1px solid transparent !important;
}
.uwa-nav__menu._search ._form form button[type=reset]:focus, .uwa-nav__menu._search ._form form button[type=reset]:hover {
  border-color: #003087 !important;
}
@media (max-width: 767.99px) {
  .uwa-nav__menu._search ._form form button[type=submit] {
    width: 80px;
    padding: 0;
  }
}
@media (max-width: 379.99px) {
  .uwa-nav__menu._search ._form form {
    flex-wrap: wrap;
  }
}

@media (min-width: 768px) {
  .uwa-nav__menu ._title {
    position: relative;
  }
  .uwa-nav__menu ._title h2 a {
    color: #ffffff;
    position: relative;
  }
  .uwa-nav__menu.__animate ._title h2 a {
    color: inherit;
    animation: uwa-nav_title 400ms linear;
  }
  .uwa-nav__menu._megamenu ._title, .uwa-nav__menu._megamenu ._promo, .uwa-nav__menu._megamenu ._links, .uwa-nav__menu._megamenu ._form {
    padding-left: 24px;
  }
  .uwa-nav__menu._megamenu ._title {
    padding-top: 16px;
  }
  .uwa-nav__menu._megamenu ._title:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 50px;
    content: "";
    background-color: #003087;
    clip-path: polygon(0% 100%, 8px 100%, 8px 100%, 8px 100%, 8px 100%, 0 100%);
  }
  .uwa-nav__menu._megamenu.__animate ._title h2 a {
    animation: uwa-nav_accent-title 400ms linear;
  }
  .uwa-nav__menu._megamenu.__animate ._title:before {
    animation: uwa-nav_accent 400ms linear;
    clip-path: polygon(0% 0%, 100% 0%, 100% 8px, 8px 8px, 8px 100%, 0 100%);
  }
}
@keyframes uwa-nav_title {
  0% {
    opacity: 0;
    top: -8px;
  }
  60% {
    opacity: 1;
    top: 0;
  }
}
@keyframes uwa-nav_accent-title {
  0%, 40% {
    opacity: 0;
    left: -16px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
@keyframes uwa-nav_accent {
  0%, 20% {
    clip-path: polygon(0% 100%, 8px 100%, 8px 100%, 8px 100%, 8px 100%, 0 100%);
  }
  40% {
    clip-path: polygon(0% 0%, 8px 0%, 8px 8px, 8px 8px, 8px 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0% 0%, 100% 0%, 100% 8px, 8px 8px, 8px 100%, 0 100%);
  }
}
@media (max-width: 767.99px) {
  .uwa-nav__menu {
    position: relative;
  }
  .uwa-nav__menu.__animate {
    animation: uwa-nav_slide-right 100ms linear;
    left: 0;
  }
  .uwa-nav__menu.__mobile-root.__animate, .uwa-nav__menu._search.__animate {
    animation: uwa-nav_slide-left 100ms linear;
  }
}
@keyframes uwa-nav_slide-right {
  0% {
    opacity: 0;
    left: 16px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
@keyframes uwa-nav_slide-left {
  0% {
    opacity: 0;
    left: -16px;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
#uwa-header__row-tertiary ol,
.uwa-nav__breadcrumbs ol {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  align-content: flex-start;
  list-style: none;
  margin: 0;
  width: 100%;
  font-size: 12px;
  color: #686868;
  line-height: 18px;
  height: 30px;
  overflow: hidden;
  position: relative;
  transition: height 200ms ease;
  position: relative;
}
#uwa-header__row-tertiary ol li, #uwa-header__row-tertiary ol a,
.uwa-nav__breadcrumbs ol li,
.uwa-nav__breadcrumbs ol a {
  font-size: 12px;
  color: #686868 !important;
  font-family: "Plus Jakarta Sans", "Century Gothic", sans-serif;
  vertical-align: middle;
  line-height: 19px;
}
#uwa-header__row-tertiary ol a,
.uwa-nav__breadcrumbs ol a {
  min-height: 30px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: inline-block;
  outline: none;
  box-sizing: border-box;
  border: 1px solid transparent;
  font-weight: 500 !important;
  text-decoration: none;
}
#uwa-header__row-tertiary ol a:link,
.uwa-nav__breadcrumbs ol a:link {
  text-decoration: none;
}
#uwa-header__row-tertiary ol a:hover, #uwa-header__row-tertiary ol a:focus-visible,
.uwa-nav__breadcrumbs ol a:hover,
.uwa-nav__breadcrumbs ol a:focus-visible {
  text-decoration: underline;
}
#uwa-header__row-tertiary ol li:last-child a,
.uwa-nav__breadcrumbs ol li:last-child a {
  font-weight: 400 !important;
}
#uwa-header__row-tertiary ol img,
.uwa-nav__breadcrumbs ol img {
  vertical-align: middle;
}
#uwa-header__row-tertiary ol li:not(:first-child):not(._close),
.uwa-nav__breadcrumbs ol li:not(:first-child):not(._close) {
  background-image: url("../img/breadcrumb-chevron_grey.svg");
  background-repeat: no-repeat;
  background-size: 16px;
  padding-left: 20px;
  background-position: left center;
}
#uwa-header__row-tertiary ol li:not(:last-child),
.uwa-nav__breadcrumbs ol li:not(:last-child) {
  padding-right: 4px;
}
#uwa-header__row-tertiary ol.__open,
.uwa-nav__breadcrumbs ol.__open {
  padding-right: 30px;
}
#uwa-header__row-tertiary ol.__open li._close,
.uwa-nav__breadcrumbs ol.__open li._close {
  display: block;
}
#uwa-header__row-tertiary ol:not(.__open),
.uwa-nav__breadcrumbs ol:not(.__open) {
  flex-wrap: nowrap;
  max-width: 100%;
}
#uwa-header__row-tertiary ol:not(.__open) li._close,
.uwa-nav__breadcrumbs ol:not(.__open) li._close {
  display: none;
}
#uwa-header__row-tertiary ol:not(.__open) li, #uwa-header__row-tertiary ol:not(.__open) a,
.uwa-nav__breadcrumbs ol:not(.__open) li,
.uwa-nav__breadcrumbs ol:not(.__open) a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#uwa-header__row-tertiary ol:not(.__open) li:first-child,
.uwa-nav__breadcrumbs ol:not(.__open) li:first-child {
  flex-shrink: 0;
}
#uwa-header__row-tertiary ol:not(.__open) li:last-child,
.uwa-nav__breadcrumbs ol:not(.__open) li:last-child {
  flex-basis: 100px;
  flex-grow: 1;
  flex-shrink: 1;
}
#uwa-header__row-tertiary ol:not(.__open) li a, #uwa-header__row-tertiary ol:not(.__open) li button,
.uwa-nav__breadcrumbs ol:not(.__open) li a,
.uwa-nav__breadcrumbs ol:not(.__open) li button {
  line-height: inherit;
  height: inherit;
  max-width: 100%;
}
@media (min-width: 768px) {
  #uwa-header__row-tertiary ol:not(.__open):has(:nth-child(5)) li,
  .uwa-nav__breadcrumbs ol:not(.__open):has(:nth-child(5)) li {
    display: none;
  }
  #uwa-header__row-tertiary ol:not(.__open):has(:nth-child(5)) li:nth-child(3),
  .uwa-nav__breadcrumbs ol:not(.__open):has(:nth-child(5)) li:nth-child(3) {
    display: block;
    flex-shrink: 0;
  }
  #uwa-header__row-tertiary ol:not(.__open):has(:nth-child(5)) li:nth-child(3) a,
  .uwa-nav__breadcrumbs ol:not(.__open):has(:nth-child(5)) li:nth-child(3) a {
    --nav-expander: true;
    background-image: url("../img/breadcrumb-open_grey.svg");
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center;
    font-size: 0;
    width: 30px;
  }
  #uwa-header__row-tertiary ol:not(.__open):has(:nth-child(5)) li:nth-child(3) a:hover, #uwa-header__row-tertiary ol:not(.__open):has(:nth-child(5)) li:nth-child(3) a:focus,
  .uwa-nav__breadcrumbs ol:not(.__open):has(:nth-child(5)) li:nth-child(3) a:hover,
  .uwa-nav__breadcrumbs ol:not(.__open):has(:nth-child(5)) li:nth-child(3) a:focus {
    border: 1px solid #b5b5b5;
  }
  #uwa-header__row-tertiary ol:not(.__open):has(:nth-child(5)) li:nth-last-child(-n+2),
  .uwa-nav__breadcrumbs ol:not(.__open):has(:nth-child(5)) li:nth-last-child(-n+2) {
    display: block;
  }
  #uwa-header__row-tertiary ol:not(.__open):has(:nth-child(5)) li:nth-child(-n+2),
  .uwa-nav__breadcrumbs ol:not(.__open):has(:nth-child(5)) li:nth-child(-n+2) {
    display: block;
  }
}
@media (min-width: 992px) {
  #uwa-header__row-tertiary ol:not(.__open) li:nth-last-child(3),
  .uwa-nav__breadcrumbs ol:not(.__open) li:nth-last-child(3) {
    display: block;
  }
}
@media (max-width: 767.99px) {
  #uwa-header__row-tertiary ol:not(.__open):has(:nth-child(4)) li,
  .uwa-nav__breadcrumbs ol:not(.__open):has(:nth-child(4)) li {
    display: none;
  }
  #uwa-header__row-tertiary ol:not(.__open):has(:nth-child(4)) li:nth-last-child(3),
  .uwa-nav__breadcrumbs ol:not(.__open):has(:nth-child(4)) li:nth-last-child(3) {
    display: block;
    background-image: none;
    padding-left: 0;
    flex-shrink: 0;
  }
  #uwa-header__row-tertiary ol:not(.__open):has(:nth-child(4)) li:nth-last-child(3) a,
  .uwa-nav__breadcrumbs ol:not(.__open):has(:nth-child(4)) li:nth-last-child(3) a {
    background-image: url("../img/breadcrumb-open_grey.svg");
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: center;
    --nav-expander: true;
    font-size: 0;
    width: 30px;
  }
  #uwa-header__row-tertiary ol:not(.__open):has(:nth-child(4)) li:nth-last-child(3) a:hover, #uwa-header__row-tertiary ol:not(.__open):has(:nth-child(4)) li:nth-last-child(3) a:focus,
  .uwa-nav__breadcrumbs ol:not(.__open):has(:nth-child(4)) li:nth-last-child(3) a:hover,
  .uwa-nav__breadcrumbs ol:not(.__open):has(:nth-child(4)) li:nth-last-child(3) a:focus {
    border: 1px solid #b5b5b5;
  }
  #uwa-header__row-tertiary ol:not(.__open):has(:nth-child(4)) li:nth-last-child(-n+2),
  .uwa-nav__breadcrumbs ol:not(.__open):has(:nth-child(4)) li:nth-last-child(-n+2) {
    display: block;
  }
}

#uwa-header__row-tertiary .breadcrumb, #uwa-header__row-tertiary .cell,
.uwa-nav__breadcrumbs .breadcrumb,
.uwa-nav__breadcrumbs .cell {
  flex-shrink: 1 !important;
}
#uwa-header__row-tertiary ol .separator,
.uwa-nav__breadcrumbs ol .separator {
  display: none;
}
#uwa-header__row-tertiary ol div:before,
.uwa-nav__breadcrumbs ol div:before {
  display: none;
}
#uwa-header__row-tertiary ol div,
.uwa-nav__breadcrumbs ol div {
  padding: 0px;
}
#uwa-header__row-tertiary .breadcrumb-item + .breadcrumb-item::before,
.uwa-nav__breadcrumbs .breadcrumb-item + .breadcrumb-item::before {
  display: none;
}

/* * PAGE FOOTER
 * * As this may be used standalone, no common css classes are used!
 * */
[class*=uwa-nav], [id^=uwa-footer] {
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
}
[class*=uwa-nav] button, [class*=uwa-nav] a, [class*=uwa-nav] img, [class*=uwa-nav] ul, [class*=uwa-nav] li, [class*=uwa-nav] h1, [class*=uwa-nav] h2, [class*=uwa-nav] h3, [class*=uwa-nav] p, [class*=uwa-nav] button, [class*=uwa-nav] input, [class*=uwa-nav] address, [id^=uwa-footer] button, [id^=uwa-footer] a, [id^=uwa-footer] img, [id^=uwa-footer] ul, [id^=uwa-footer] li, [id^=uwa-footer] h1, [id^=uwa-footer] h2, [id^=uwa-footer] h3, [id^=uwa-footer] p, [id^=uwa-footer] button, [id^=uwa-footer] input, [id^=uwa-footer] address {
  font-family: "Plus Jakarta Sans", "Century Gothic", sans-serif;
  font-size: 13px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  vertical-align: baseline;
  text-transform: none;
  transition: none;
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;
  box-shadow: none;
  line-height: inherit;
}
[class*=uwa-nav] ul, [id^=uwa-footer] ul {
  list-style: none;
}
[class*=uwa-nav] img, [id^=uwa-footer] img {
  vertical-align: bottom;
}

[id^=uwa-footer__row] {
  background-color: #003087;
  color: white;
  font-size: 13px;
}
[id^=uwa-footer__row] h1 {
  color: white;
}
[id^=uwa-footer__row] > * {
  max-width: 1200px;
  box-sizing: content-box;
  padding-left: 48px;
  padding-right: 48px;
  padding-left: var(--bmr-page-margin);
  padding-right: var(--bmr-page-margin);
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 24px;
}
[id^=uwa-footer__row] a {
  text-decoration: underline;
  color: #ffffff;
}
[id^=uwa-footer__row] a:link {
  text-decoration: underline;
}
[id^=uwa-footer__row] a:hover, [id^=uwa-footer__row] a:focus-visible {
  text-decoration: none;
}
[id^=uwa-footer__row] strong {
  font-weight: 600;
}
[id^=uwa-footer__row] details summary {
  list-style: none;
  line-height: 24px;
}
[id^=uwa-footer__row] details summary::-webkit-details-marker {
  display: none;
}
[id^=uwa-footer__row] details summary:hover, [id^=uwa-footer__row] details summary:focus {
  text-decoration: underline;
}
[id^=uwa-footer__row] details summary:after {
  background-image: url("../img/menu-chevron_white.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-size: 14px;
  display: inline-block;
  content: "";
  vertical-align: bottom;
  width: 24px;
  height: 24px;
  transform: rotate(0deg);
  transition: transform 200ms ease;
}
[id^=uwa-footer__row] details[open] {
  background-color: rgba(255, 255, 255, 0.05);
}
[id^=uwa-footer__row] details[open] summary:after {
  transform: rotate(180deg);
}

#uwa-footer__row-back {
  background-color: transparent;
  margin-top: 96px;
}
#uwa-footer__row-back > * {
  display: flex;
  flex-direction: row nowrap;
  justify-content: flex-end;
}
#uwa-footer__row-back > *:has(a + a) {
  justify-content: space-between;
}
#uwa-footer__row-back a {
  text-decoration: underline;
  color: #0e55b5;
  display: inline-block;
  font-size: 15px;
}
#uwa-footer__row-back a:link {
  text-decoration: underline;
}
#uwa-footer__row-back a:hover, #uwa-footer__row-back a:focus-visible {
  text-decoration: none;
}
#uwa-footer__row-back a._top {
  background-image: url("../img/arrow-up.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  padding-right: 28px;
  background-position: right center;
}
#uwa-footer__row-back a._home {
  background-image: url("../img/arrow-left.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  padding-left: 28px;
  background-position: left center;
}

#uwa-footer__row-primary, #uwa-footer__row-secondary, #uwa-footer__row-tertiary {
  color: #ffffff;
}
#uwa-footer__row-primary a, #uwa-footer__row-secondary a, #uwa-footer__row-tertiary a {
  color: #ffffff !important;
}

#uwa-footer__row-primary {
  padding-top: 16px;
}
#uwa-footer__row-primary h1 {
  padding-top: 24px;
  margin-bottom: 24px;
}
#uwa-footer__row-primary h1 a {
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  color: #ffffff;
}
#uwa-footer__row-primary h1 a:link {
  text-decoration: none;
}
#uwa-footer__row-primary h1 a:hover, #uwa-footer__row-primary h1 a:focus-visible {
  text-decoration: underline;
}
#uwa-footer__row-primary .uwa-footer__info {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
#uwa-footer__row-primary .uwa-footer__info p:not(:last-child) {
  margin-bottom: 8px;
}
#uwa-footer__row-primary .uwa-footer__info address, #uwa-footer__row-primary .uwa-footer__info ._address {
  grid-column: span 12;
}
@media (min-width: 768px) {
  #uwa-footer__row-primary .uwa-footer__info address, #uwa-footer__row-primary .uwa-footer__info ._address {
    grid-column: span 6;
  }
}
@media (min-width: 992px) {
  #uwa-footer__row-primary .uwa-footer__info address, #uwa-footer__row-primary .uwa-footer__info ._address {
    grid-column: span 4;
  }
}
#uwa-footer__row-primary .uwa-footer__info address strong, #uwa-footer__row-primary .uwa-footer__info ._address strong {
  font-size: 17px;
}
#uwa-footer__row-primary .uwa-footer__info address a, #uwa-footer__row-primary .uwa-footer__info ._address a {
  text-decoration: none;
}
#uwa-footer__row-primary .uwa-footer__info address a:link, #uwa-footer__row-primary .uwa-footer__info ._address a:link {
  text-decoration: none;
}
#uwa-footer__row-primary .uwa-footer__info address a:hover, #uwa-footer__row-primary .uwa-footer__info address a:focus-visible, #uwa-footer__row-primary .uwa-footer__info ._address a:hover, #uwa-footer__row-primary .uwa-footer__info ._address a:focus-visible {
  text-decoration: underline;
}
#uwa-footer__row-primary .uwa-footer__info nav, #uwa-footer__row-primary .uwa-footer__info ._links {
  grid-column: span 12;
}
@media (min-width: 768px) {
  #uwa-footer__row-primary .uwa-footer__info nav, #uwa-footer__row-primary .uwa-footer__info ._links {
    grid-column: span 6;
  }
}
@media (min-width: 992px) {
  #uwa-footer__row-primary .uwa-footer__info nav, #uwa-footer__row-primary .uwa-footer__info ._links {
    grid-column: span 3;
  }
}
#uwa-footer__row-primary .uwa-footer__info nav ul, #uwa-footer__row-primary .uwa-footer__info ._links ul {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 8px;
}
#uwa-footer__row-primary .uwa-footer__info aside, #uwa-footer__row-primary .uwa-footer__info ._indigenous {
  grid-column: span 12;
  background-image: url("../img/footer/indigenous_light.png");
  background-position: 8px 0;
  background-repeat: no-repeat;
  background-size: 300px auto;
}
@media (min-width: 768px) {
  #uwa-footer__row-primary .uwa-footer__info aside, #uwa-footer__row-primary .uwa-footer__info ._indigenous {
    grid-column: span 12;
  }
}
@media (min-width: 992px) {
  #uwa-footer__row-primary .uwa-footer__info aside, #uwa-footer__row-primary .uwa-footer__info ._indigenous {
    grid-column: span 5;
  }
}
#uwa-footer__row-primary .uwa-footer__info aside p:first-child, #uwa-footer__row-primary .uwa-footer__info ._indigenous p:first-child {
  font-family: "Petrona", "Palatino", serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
#uwa-footer__row-primary .uwa-footer__info details {
  padding: 8px;
  margin-left: -8px;
  display: inline-block;
}
#uwa-footer__row-primary .uwa-footer__info details:not(:first-child) {
  margin-top: 32px;
}
#uwa-footer__row-primary .uwa-footer__info details ._flag-australia, #uwa-footer__row-primary .uwa-footer__info details ._flag-china {
  padding-left: 40px;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 32px auto;
  line-height: 24px;
}
#uwa-footer__row-primary .uwa-footer__info details ._flag-australia {
  background-image: url("../img/flag-australia.png");
}
#uwa-footer__row-primary .uwa-footer__info details ._flag-china {
  background-image: url("../img/flag-china.png");
}
#uwa-footer__row-primary .uwa-footer__info details p {
  margin-top: 8px;
}
#uwa-footer__row-primary .uwa-footer__info details p a {
  display: block;
  text-decoration: none;
}
#uwa-footer__row-primary .uwa-footer__info details p a:link {
  text-decoration: none;
}
#uwa-footer__row-primary .uwa-footer__info details p a:hover, #uwa-footer__row-primary .uwa-footer__info details p a:focus-visible {
  text-decoration: underline;
}

#uwa-footer__row-secondary .uwa-footer__network {
  padding: 16px 0;
  border-width: 1px 0 1px 0;
  border-color: #ffffff;
  border-style: solid;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 24px;
}
#uwa-footer__row-secondary .uwa-footer__network > * {
  grid-column: span 12;
}
@media (min-width: 768px) {
  #uwa-footer__row-secondary .uwa-footer__network > * {
    grid-column: span 6;
  }
}
#uwa-footer__row-secondary .uwa-footer__network ul {
  display: flex;
  align-items: center;
  gap: 24px;
}
#uwa-footer__row-secondary .uwa-footer__network img {
  height: 50px;
}
#uwa-footer__row-secondary .uwa-footer__network a[class^=_icon] {
  line-height: 200px;
  overflow: hidden;
  width: 40px;
  height: 40px;
  display: block;
  text-decoration: none;
  background-size: 32px;
  background-repeat: no-repeat;
  background-position: center;
}
#uwa-footer__row-secondary .uwa-footer__network a[class^=_icon]:hover {
  background-color: #ffffff;
}
#uwa-footer__row-secondary .uwa-footer__network ._icon-facebook {
  background-image: url("../img/social-facebook_white.svg");
}
#uwa-footer__row-secondary .uwa-footer__network ._icon-facebook:hover, #uwa-footer__row-secondary .uwa-footer__network ._icon-facebook:focus {
  background-image: url("../img/social-facebook_blue.svg");
}
#uwa-footer__row-secondary .uwa-footer__network ._icon-instagram {
  background-image: url("../img/social-instagram_white.svg");
}
#uwa-footer__row-secondary .uwa-footer__network ._icon-instagram:hover, #uwa-footer__row-secondary .uwa-footer__network ._icon-instagram:focus {
  background-image: url("../img/social-instagram_blue.svg");
}
#uwa-footer__row-secondary .uwa-footer__network ._icon-x {
  background-image: url("../img/social-x_white.svg");
}
#uwa-footer__row-secondary .uwa-footer__network ._icon-x:hover, #uwa-footer__row-secondary .uwa-footer__network ._icon-x:focus {
  background-image: url("../img/social-x_blue.svg");
}
#uwa-footer__row-secondary .uwa-footer__network ._icon-youtube {
  background-image: url("../img/social-youtube_white.svg");
}
#uwa-footer__row-secondary .uwa-footer__network ._icon-youtube:hover, #uwa-footer__row-secondary .uwa-footer__network ._icon-youtube:focus {
  background-image: url("../img/social-youtube_blue.svg");
}
#uwa-footer__row-secondary .uwa-footer__network ._icon-linkedin {
  background-image: url("../img/social-linkedin_white.svg");
}
#uwa-footer__row-secondary .uwa-footer__network ._icon-linkedin:hover, #uwa-footer__row-secondary .uwa-footer__network ._icon-linkedin:focus {
  background-image: url("../img/social-linkedin_blue.svg");
}
#uwa-footer__row-secondary .uwa-footer__network ._icon-tiktok {
  background-image: url("../img/social-tiktok_white.svg");
}
#uwa-footer__row-secondary .uwa-footer__network ._icon-tiktok:hover, #uwa-footer__row-secondary .uwa-footer__network ._icon-tiktok:focus {
  background-image: url("../img/social-tiktok_blue.svg");
}

#uwa-footer__row-tertiary details[open] summary {
  margin-bottom: 8px;
}