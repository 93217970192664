/*
 * UWA Universal Navigation
 * For SiteCore SXA Student and News sites
 * Timothy Phillips, Digital Channel, September 2024
 */

// @import "https://static.weboffice.uwa.edu.au/visualid/fonts/ttnormspro/ttnormspro.css"
// @import "https://static.weboffice.uwa.edu.au/visualid/fonts/novelpro/novelpro.css"

@import url('https://static.weboffice.uwa.edu.au/visualid/fonts/petrona/petrona.css')
@import url('https://static.weboffice.uwa.edu.au/visualid/fonts/plus-jakarta-sans/plus-jakarta-sans.css')


// These may be needed before variables
@import navigation/support/nav_support.sass
@import navigation/support/nav_vars.sass
@import navigation/support/nav_mixins.sass
@import navigation/support/nav_mix_objects.sass
@import navigation/support/nav_mix_type.sass


// print the css variables seperately, default scope is root:
// @include define-css-vars('.uwa-nav__reset')
@include define-css-vars()
//('[class*=uwa-header__row], [id^=uwa-header__row], .uwa-nav__menus, [id^=uwa-footer__row]')



.uwa__match-header-width
    @include page-content-block()
// Elements -- Default styles with NO classes

body
    margin: 0
    padding: 0
    min-height: 100vh

// Page -- Common page elements outside the content area.
// @import 3_components/c_page/pag_common.sass
@import navigation/nav_header-layout_old-sxa.sass
@import navigation/nav_header-items.sass
@import navigation/nav_header-menus.sass
@import navigation/nav_breadcrumbs.sass
// @import navigation/nav_sidenav-wrapper.sass
// @import navigation/nav_sidenav-content.sass
@import navigation/nav_footer.sass
